import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, WYSIWYG, NotationBlock } from '../../util/typography';
import * as CTA from '../../util/CTA';
import ComponentWrapper from '../layout/ComponentWrapper';
import { between } from 'polished';
import { createMarkup, stringToSlug } from '../../util/functions';
import {EarlierStageIndicationGrid} from '../../components';


const StyledComponentWrapper = styled(ComponentWrapper)`
    border-bottom: 1px solid ${colors.pebble};
    max-width: 824px;
    padding-bottom: 20px;

    @media (min-width: 320px) and (max-width: 1032px) {
        padding-bottom: ${between('20px', '30px', '320px', '1032px')};
    }

    @media (min-width: 1033px) {
        padding-bottom: 30px;
    }

    &:last-child {
        border-bottom: none;
    }
`;
const AnchorTarget = styled.span`
    display: block;
    height: 0;
    overflow: hidden;
    line-height:none;
    transform: translateY(-155px); //manage offset for fully-expanded sticky nav
`;

const IndicationCTAContent = styled.article``;

const IndicationCTATitle = styled(Heading.H4)`
    margin-bottom: 9px;
`;

const ActionContainer = styled.div`
    margin-top: 0.5em;
    display: block;
`;



const IndicationCTA = props => {

    const [anchorId, setAnchorId] = useState(props.anchorId);
    const [anchorIdArr, setAnchorIdArr] = useState([]);

    useEffect(() => {
        const delimiter = '||';
        
        if (anchorId && anchorId.includes(delimiter)) {
            const result = anchorId.split(delimiter).map(item => item.trim());
            setAnchorIdArr(result);
        } else {
            setAnchorIdArr([anchorId]);
        }
    },[anchorId]);

    return (
        <StyledComponentWrapper>
            {/*props.esi && <EarlierStageIndicationGrid />*/}
            { anchorIdArr && anchorIdArr.map(item => <AnchorTarget id={stringToSlug(item)}/>) }           
            
            <IndicationCTAContent>
                <IndicationCTATitle as='H4' dangerouslySetInnerHTML={createMarkup(props.title)} />
                <WYSIWYG dangerouslySetInnerHTML={createMarkup(props.body)} />
                {(props.definitions) && <NotationBlock definitions={props.definitions} />}
                <ActionContainer>
                    <CTA.TertiaryButton {...props.cta} />
                </ActionContainer>
            </IndicationCTAContent>
        </StyledComponentWrapper>
    );
};

export default IndicationCTA;