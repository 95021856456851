import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Text } from '../../util/typography';
import { BaseChip } from './base';
import { between } from 'polished';

const RateChip = styled(BaseChip)``;

// make a text style
const RatePercent = styled(Text.Body)`
    display: block;
    ${'' /* New text styles */}
    font-size: clamp(60px, 7.8vw, 80px);
    margin-top: .38em;
    margin-bottom: 10px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: center;
    color: inherit;

    sup {
        font-size: 42%;
        vertical-align: top;
        position: relative;
        top: 0.5em;
    }
`;

const RateTitle = styled(Text.Body)`
    display: block;
    ${'' /* new text styles */}
    font-size: clamp(22px, 2.3vw, 24px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: inherit;
    margin-top: .4em;
`;

// this was breaking the flex wrap
const RateWrapper = styled.div`
    padding-top: ${between('30px', '40px', '320px', '1032px')};
    padding-right: 20px;
    padding-bottom: ${between('20px', '40px', '320px', '1032px')};
    padding-left: 20px;
`;

const StyledRateTile = styled.div`
    --primaryText: ${colors.white};
    --chipBorder: var(--primaryText);
    --chipText: var(--primaryText);
    background: var(--bg);
    text-align: center;
    color: var(--primaryText);
    flex-basis: var(--basis);
    flex-grow: 1;
    flex-shrink: 1;
    border: none;
`;

const StatLegend = styled(Text.Body)`
    display: block;
`;

const RateTile = (props) => {
    return (
        <StyledRateTile>
            <RateWrapper>
                <RateChip>{props.dataType}</RateChip>
                <RatePercent dangerouslySetInnerHTML={{__html: `${props.rate}`}} />
                {props.ci && <StatLegend theme="light" dangerouslySetInnerHTML={{__html: `(${props.ci})` }} />}
                {props.n && <StatLegend theme="light" dangerouslySetInnerHTML={{__html: `(n/N=${props.n})` }} />}
                {props.p && <StatLegend theme="light" dangerouslySetInnerHTML={{__html: `<i>P</i>=${props.p}`}} />}
                <RateTitle dangerouslySetInnerHTML={{__html: props.label }} />
            </RateWrapper>
        </StyledRateTile>
    );
};

RateTile.defaultProps = {
    rate: '17%',
    title: 'Keytruda',
    subtitle: '(n=16/83; 95% Cl, 11-29)',
};

export default RateTile;
