import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import AccordionDrawer from './AccordionDrawer';
import FilterTrigger from './FilterTrigger';
import ComponentWrapper from '../ComponentWrapper';
// import { useAccordion, AccordionProvider } from '../../AccordionContext'
import AnchorLinks from '../../AnchorLinks';

const StyledComponentWrapper = styled(ComponentWrapper)`
    ${props => props.homepage && `margin: 0 auto 20px;`}
`;

const RedirectionLink = styled.div`
    max-width: 864px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

    @media (max-width: 480px) {
        padding: 0;
    }
    
    > div{
        margin: 0 0 30px 0;
    }
`;

const anchorLinkModelData = [
    { id: 1, label: 'NSCLC (KEYNOTE-671)'}, 
    { id: 1, label: 'NSCLC (KEYNOTE-091)' }, 
    { id: 17, label: 'TNBC (KEYNOTE-522)' },
    { id: 0, label: 'Melanoma (KEYNOTE-716)' },
    { id: 0, label: 'Melanoma (KEYNOTE-054)'},
    { id: 6, label: ' NMIBC (KEYNOTE-057)'},
    { id: 14, label: 'RCC (KEYNOTE-564)'}   
]


const AccordionGroup = props => {
    // const { openAccordion } = useAccordion();
    //for AT/screenshots
    const [openAllItemsOverride, setOpenAllItemsOverride] = useState(false);

    const [openItems, setOpenItems] = useState([]);
    const clearTimeRef = useRef(null);

    const clickHandler = id => {
        console.log('clickHandler called')
        if (openItems.includes(id)) {
            setOpenItems(openItems => openItems.filter(item => item !== id));
        } else {      
            setOpenItems(openItems => [...openItems, id]);
        }
    };

      // This function will handle opening accordions based on the anchor link click
    const handleAnchorLinkClick = (id, hash) => {

        setOpenItems(openItems => [...openItems, id]);
        console.log(openItems)

        clearTimeRef.current = setTimeout(() => {
            if (hash && document.querySelector(hash)) {
                console.log("document:",document);
                const element = document.querySelector(hash);
                const rect = element.getBoundingClientRect();
                const offset = 50; 
                const scrollPosition = window.scrollY + rect.top - offset;
        
                window.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth'
                });
                window.history.pushState(null, '', `${hash}`);
            }
        }, 0);
    };
    
    useEffect(() => {
        //for AT/screenshots - provide a postMessage listener so the accordions can be opened via client-side JS with window.postMessage({action: 'openAllAccordions'}) 
        window.addEventListener("message", (event) => {
            if (event.data.action === 'openAllAccordions') {
                setOpenAllItemsOverride(true);
            }            
          }, false);

        return () => {
            if (clearTimeRef.current) {
              clearTimeout(clearTimeRef.current);
            }
        };
    },[])

    useEffect(() => {
        if (props.defaultAllOpen) {
            //set the open items array to match the length of the array, which will open all accordions on page load
            setOpenItems([...props.items.keys()]);
        } else {
            setOpenItems([]);
        }
    },[props.defaultAllOpen])


    // useEffect(() => {
    //     // Retrieve keynote ID from local storage
    //     const storedKeynoteId = sessionStorage.getItem('keynoteID');
    //     if (storedKeynoteId) {
    //         setKeynoteId(storedKeynoteId);
    //     }
    // }, []); // Run once on mount

    // useEffect(() => {
    //     if (props.listType === 'efficacy' && keynoteId !== null) {
    //         const hash = window.location.hash; // Current hash from URL

    //         // Trigger necessary handlers
    //         clickHandler(1)
    //         //handleAnchorLinkClick(keynoteId, hash)

    //         console.log(`Keynote ID: ${keynoteId}, Hash: ${hash}`);
    //     }
    //     else {
    //         console.log('keynoteId',keynoteId)
    //     }
    // }, [props.listType, keynoteId]);
    

    return (
        <StyledComponentWrapper homepage={props.homepage}>
            {/* {(props.listType === 'efficacy' || props.listType === 'safety') && <RedirectionLink><AnchorLinks title="Earlier-Stage Indications" items={anchorLinkModelData} onLinkClick={handleAnchorLinkClick} listType={props.listType}/></RedirectionLink>} */}
            {props.includeFilter && <FilterTrigger/>}
            {props.items.map((item, i) => {
               
            return (
                <AccordionDrawer
                    key={i}
                    drawerId={i} //for animation
                    isOpen={openItems.includes(i) || openAllItemsOverride}
                    clickHandler={() => clickHandler(i)}
                    {...item}
                    transcript={props.transcript}
                >
                    {item.children}
                </AccordionDrawer>
            )})}
        </StyledComponentWrapper>
    );
};

export default AccordionGroup;