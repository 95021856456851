import React, { useEffect, useRef,useState } from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { important, rgba } from 'polished';
import { createLocalScaleFunction } from '../../util/functions';
import { useSelector, useDispatch } from 'react-redux';
import {
    openHcpAcknowledgement,
    closeHcpAcknowledgement,
    allowScrolling,
    preventScrolling,
} from '../../store/actions';
import { ARE_YOU_AN_HCP_MODAL_OVERLAY } from '../../constants/z-index-map';
import { HAS_ACKNOWLEDGED_HCP_KEY } from '../../store/storage-keys';
import useTrapFocus from '../../util/accessibility/useTrapFocus';
import useKeydownHandler from '../../util/accessibility/useKeydownHandler';

const VERTICAL_BREAKPOINT = '500px';

const ModalOverlay = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: ${rgba(colors.ink, 0.8)};
    z-index: ${ARE_YOU_AN_HCP_MODAL_OVERLAY};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    animation: fade-in 0.3s 0.2s forwards;
    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const ModalWrapper = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
`;

const ModalContainer = styled.div`
    max-width: 800px;
    margin: 20px;
    border-radius: 8px;
    background: ${colors.white};
    max-height: calc(100% - 40px);
    overflow: auto;
    overscroll-behavior: contain;
`;

const ModalContent = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;

    > div {
        box-sizing: border-box;
        padding: ${createLocalScaleFunction(30, 280, 40, 835)} ${createLocalScaleFunction(20, 280, 50, 835)};
    }
`;

const ContentContainer = styled.div`
    text-align: center;
`;

const CTARow = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin: 37px -20px 38px;
    justify-content: space-between;

    @media (max-width: ${VERTICAL_BREAKPOINT}) {
        flex-direction: column;
        margin: 0;
    }

    > button,
    > a {
        flex: 1 1 150px;
        margin: 20px 10px 0;
        justify-content: center;
    }
`;

const CTAContainer = styled.div`
    flex: 1 1 40%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: ${VERTICAL_BREAKPOINT}) {
        padding: 20px 0;
        align-items: stretch;
    }
`;

const Title = styled(Heading.H3)`
    @media (max-width: ${VERTICAL_BREAKPOINT}) {
        ${Heading.getHeadingStylesByType('4')}
    }
`;

const Descriptor = styled(Heading.H5)`
    margin-bottom: 20px;

    @media (max-width: ${VERTICAL_BREAKPOINT}) {
        ${Heading.getHeadingStylesByType('7')}
    }
`;

const Divider = styled.div`
    width: 1px;
    height: auto;
    background-color: ${colors.pebble};

    @media (max-width: ${VERTICAL_BREAKPOINT}) {
        width: auto;
        height: 1px;
    }
`;

const DisclaimerText = styled(Text.Body)`
    display: block;
    margin: 30px 0 0;

    @media (max-width: ${VERTICAL_BREAKPOINT}) {
        text-align: left;
        margin: 10px 0 0;
        ${Text.getTextStylesByType('caption')}
    }
`;

const HCPAcknowledgement = ({ hcp_acknowledgement_ref }) => {
    // const [isOpen, setIsOpen] = useState();
    const hcpAcknowledgementOpen = useSelector(state => state.hcpAcknowledgementOpen);
    const [hideCCPR, setHideCCPR] = useState(true);
    const dispatch = useDispatch();

    const clickHandler = () => {
        if (typeof window !== 'undefined' && window.location.protocol !== 'data:') {
            window.sessionStorage.setItem(HAS_ACKNOWLEDGED_HCP_KEY, '1');
        }
        dispatch(closeHcpAcknowledgement());
        dispatch(allowScrolling());
    };

    useEffect(() => {
        if (typeof window !== 'undefined' && window.origin !== 'null') {
            let has_acknowledged = window.sessionStorage.getItem(HAS_ACKNOWLEDGED_HCP_KEY);
            if (!has_acknowledged && !hcpAcknowledgementOpen) {
                dispatch(openHcpAcknowledgement());
                dispatch(preventScrolling());
            }
        }
    }, [dispatch, hcpAcknowledgementOpen]);

    useTrapFocus(hcp_acknowledgement_ref, hcpAcknowledgementOpen);

    useEffect(() => {
        const firstButton = document.querySelector('#interstitial-button-1');
        if (hcpAcknowledgementOpen && firstButton) {
            firstButton.focus();
        }
    }, [hcpAcknowledgementOpen]);

    useEffect(() => {     
        const checkCCPR = () => {
            const ccprBanner = document.querySelector('.onetrust-pc-dark-filter');
            let has_acknowledged = window.sessionStorage.getItem(HAS_ACKNOWLEDGED_HCP_KEY);
            if(ccprBanner){
                console.log('has_acknowledged', has_acknowledged)
                   if(has_acknowledged === 'null' || has_acknowledged!== 1  ){            
                    ccprBanner.style.setProperty('background', 'none', 'important');
                    }
                   if(has_acknowledged == '1'){
                    ccprBanner.style.setProperty('background', 'rgba(38, 38, 38, 0.8)', 'important');
                   }
                clearInterval(ccprInterval);
            }
         }
        const ccprInterval = setInterval(checkCCPR, 300);
        return () => clearInterval(ccprInterval);
    }, [hcpAcknowledgementOpen]);
    
    return (
        <>
            {hcpAcknowledgementOpen && (
                <ModalOverlay ref={hcp_acknowledgement_ref} tabindex="0">
                    <ModalWrapper>
                        <ModalContainer>
                            <ModalContent>
                                <ContentContainer>
                                    <Title>This site is intended for health care professionals</Title>
                                    <CTARow>
                                        <CTAContainer>
                                            <Descriptor>I am a health care professional</Descriptor>
                                            <CTA.PrimaryButton
                                                as="button"
                                                id="interstitial-button-1"
                                                label="Proceed to site"
                                                onClick={clickHandler}
                                                tabIndex="0"
                                                aria-label="Proceed to site"
                                            />
                                        </CTAContainer>
                                        <Divider />
                                        <CTAContainer>
                                            <Descriptor>I am a patient or caregiver</Descriptor>
                                            <CTA.PrimaryButton
                                                as="a"
                                                id="interstitial-button-2"
                                                label="Visit patient site"
                                                href="https://keytruda.com"
                                                tabIndex="0"
                                                aria-label="Visit patient site"
                                            />
                                        </CTAContainer>
                                    </CTARow>
                                    <DisclaimerText>
                                        The information on this site is intended for health care
                                        professionals in the United States, its territories, and
                                        Puerto Rico, and is not intended for the general public.
                                    </DisclaimerText>
                                </ContentContainer>
                            </ModalContent>
                        </ModalContainer>
                    </ModalWrapper>
                </ModalOverlay>
            )}
        </>
    );
};

export default HCPAcknowledgement;
