import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import MenuLink from './MenuLinkSmall';
import SubMenu from './SubMenuSmall';
import { Text } from '../../../util/typography';
import * as CTA from '../../../util/CTA';
import * as colors from '../../../util/colors';
import { closeBlueberry, caratLeftSmallBlueberry, homeBlueberry } from '../../../util/icons';
import useOnClickOutside from 'use-onclickoutside';
import { CSSTransition, Transition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { launchChatbotWindow } from '../../Chatbot/ChatbotLauncher';
import { useDispatch } from 'react-redux';
import {
    openGeneralModal,
    preventScrolling,
} from '../../../store/actions';
import { medicalInformationModal } from '../../UtilityNavigation';
import useTrapFocus from '../../../util/accessibility/useTrapFocus';

const duration = 300;

const Menu = styled.div`
    position: fixed;
    background: ${colors.white};
    top: 0;
    left: 100%;
    width: 270px;
    box-sizing: border-box;
    height: calc(100vh - 40px); //compensate for Utility Nav
    overflow: hidden;
    z-index: 100;
    display: flex;
    flex-flow: column nowrap;
    transition: transform ${duration}ms ease-in-out;

    &.mobile-menu-enter {
        transform: translateX(0);
    }

    &.mobile-menu-enter-active,
    &.mobile-menu-enter-done {
        transform: translateX(-100%);
    }

    &.mobile-menu-exit {
        transform: translateX(-100%);
    }

    &.mobile-menu-exit-active,
    &.mobile-menu-exit-done {
        transform: translateX(0);
    }
`;

const subMenuStyles = {
    gridRow: '1 / 1',
    gridColumn: '2 / 2',
    background: colors.white,
};

const subMenuTransitionStyles = {
    entering: { display: 'block' },
    entered: { display: 'block' },
    exiting: { display: 'block' },
    exited: { display: 'none' },
};

const MenuContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    width: 200%;
    background: ${colors.white};
    height: 100%;
    transition: transform 0.3s ease-in-out;
    transform: ${({ subMenuOpen }) => (subMenuOpen ? 'translateX(-50%)' : 'translateX(0)')};
    flex: 1 auto;
    overflow: hidden;

    > div {
        overflow: auto;
        overscroll-behavior: contain;
    }
`;

const MenuHeader = styled.header`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    border-bottom: 2px solid ${colors.silver};
    flex: 0 0 auto;
    overflow: hidden;
`;

const MenuFooter = styled.footer`
    padding: 30px 20px;

    > a {
        &:not(:last-child) {
            width: 100%;
        }

        &:first-child {
            margin-bottom: 20px;

            span {
                flex-grow: 1;
            }
        }

        &:not(:first-child) {
            margin-top: 10px;
        }

        &:last-child {
            margin-top: 0;
        }
    }
`;

const BackButton = styled.button`
    display: flex;
    opacity: ${({ subMenuOpen }) => (subMenuOpen ? 1 : 0)};
    flex: 1 0 auto;
    background-color: transparent;
    border: none;
    align-items: center;
    cursor: pointer;
    pointer-events: ${({ subMenuOpen }) => (subMenuOpen ? 'all' : 'none')};
    transition: opacity 0.3s ease-in-out;

    &::before {
        display: block;
        content: '';
        margin: 0 20px 2px 5px;
        width: 10px;
        height: 10px;
        background-image: url(${caratLeftSmallBlueberry});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: transform 0.3s ease-in-out;
        transform-style: preserve-3d;
        transform-origin: center;
    }
`;

const ChatButton = styled(CTA.SecondaryButton)`
    width: 100%;
`;

const CloseButton = styled.button`
    flex: 0 0 50px;
    background-color: transparent;
    border: none;
    background-image: url(${closeBlueberry});
    background-repeat: no-repeat;
    background-size: 14px 14px;
    height: 60px;
    border-left: 2px solid ${colors.silver};
    padding: 14px 10px 12px;
    background-origin: content-box;
    background-position: center top;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    margin: 0;
    box-sizing: border-box;
    appearance: none;
`;

const ButtonText = styled(Text.Button)`
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    line-height: 1;
`;

const MainMenuList = styled.ul`
    display: flex;
    flex-flow: column nowrap;
    padding: 0 0 15px;
    border-bottom: 2px solid ${colors.silver};
    margin-bottom: 0;
`;

const MainMenuItem = styled.li`
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: stretch;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
`;

const MenuLinkHome = styled(MenuLink)`
    &::after {
        display: block;
        content: '';
        margin: 0 2px;
        width: 16px;
        height: 18px;
        background-image: url(${homeBlueberry});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
`;

const MainMenuSmall = ({ items, isOpen, openItem, itemClickHandler, closeAllHandler, general_modal_ref }) => {
    const [status, setStatus] = useState('');
    const mobile_nav_ref = useRef(null);
    const scrolledPastInPageSSI = useSelector(state => state.scrolledPastInPageSSI);
    const chatbotEnabled = useSelector(state => state.chatbotEnabled);

    const dispatch = useDispatch();

    // Close everything when we click outside the main nav
    useOnClickOutside(mobile_nav_ref, e => {
        /**
         * Only close everything if something is open.
         * AND if what we've clicked on is NOT the Medical Information modal.
         */
        if (isOpen && !general_modal_ref?.current?.contains(e.target)) {
            closeAllHandler();
        }
    });
    //handle inconsistent vh values on mobile browsers by setting the height to the visible viewport on entry
    const setMenuHeight = () => {
        if (typeof window !== 'undefined' && window.location.protocol !== 'data:' ) {
            mobile_nav_ref.current.style.height = `${window.innerHeight - 40}px`;
        }
    }

    // Use trap focus inside the MainMenuSmall
    useTrapFocus(mobile_nav_ref, isOpen, [status]);

    return (
        <CSSTransition
            in={isOpen}
            appear={true}
            timeout={duration}
            mountOnEnter
            unmountOnExit
            classNames="mobile-menu"
            onEnter={setMenuHeight}
        >
            <Menu ref={mobile_nav_ref}>
                <MenuHeader>
                    <BackButton
                        dataDesignCategory="top_nav_vd"
                        subMenuOpen={openItem !== null}
                        tabIndex={openItem !== null ? '0' : '-1'}
                        onClick={() => itemClickHandler(null)}
                    >
                        <Text.Button>Back</Text.Button>
                    </BackButton>
                    <CloseButton onClick={closeAllHandler}>
                        <ButtonText>Close</ButtonText>
                    </CloseButton>
                </MenuHeader>
                <MenuContainer subMenuOpen={openItem !== null}>
                    <Transition in={openItem === null } timeout={300} unmountOnExit={true} onExited={() => { setStatus('sub-menu') }}>
                        <div>
                        <MainMenuList>
                            {items.map((item, i) => {
                            const hasChildren = item.children && item.children.length > 0;    
                            return (
                                <MainMenuItem
                                    key={i}
                                    onClick={!item.url ? () => itemClickHandler(i) : closeAllHandler}
                                >
                                    <MenuLink
                                        level="1"
                                        hasChildren={hasChildren}
                                        isOpen={openItem === i}
                                        {...(hasChildren && { as: 'button' })}
                                        {...item}
                                    />
                                </MainMenuItem>
                            )})}
                            <MainMenuItem key={items.length} onClick={closeAllHandler}>
                                <MenuLinkHome dataDesignCategory="top_nav_vd" label="Home" url="/"  />
                            </MainMenuItem>
                        </MainMenuList>
                        <MenuFooter>
                            <CTA.PrimaryButton dataDesignCategory="top_nav_vd" label="Ask Merck" url="https://www.askmerckoncology.com"/>
                            <ChatButton tagType="button" dataDesignCategory="top_nav_vd" label="Chat" disabled={!chatbotEnabled} 
                                                 onClick={() => launchChatbotWindow({ scrolledPastInPageSSI: scrolledPastInPageSSI, callback: closeAllHandler })}/>
                            <CTA.TertiaryButton dataDesignCategory="top_nav_vd"  external="true" label="Visit Patient Site" aria-label="Visit Patient Site" url="https://www.keytruda.com/" />
                            <CTA.TertiaryButton tagType="button" dataDesignCategory="top_nav_vd"  external="true" label="Medical Information"
                                onClick={() => {
                                    dispatch(openGeneralModal(medicalInformationModal))
                                    dispatch(preventScrolling())
                                }
                            } />
                        </MenuFooter>
                        </div>
                    </Transition>
                    {items.map((item, i) => (
                        <Transition in={openItem === i} timeout={300} key={i} unmountOnExit={true} onExited={() => { setStatus('main-menu') }}>
                            {state =>
                                item.children && (
                                    <div
                                        style={{
                                            ...subMenuStyles,
                                            ...subMenuTransitionStyles[state],
                                        }}
                                    >
                                        <SubMenu item={item} closeAllHandler={closeAllHandler}/>
                                    </div>
                                )
                            }
                        </Transition>
                    ))}
                </MenuContainer>
            </Menu>
        </CSSTransition>
    );
};

export default MainMenuSmall;
