import React, { useRef } from 'react';
import styled from 'styled-components';
import MenuLink from './MenuLinkLarge';
import * as colors from '../../../util/colors';
import { Transition } from 'react-transition-group';
import useTrapFocus from '../../../util/accessibility/useTrapFocus';

const duration = 200;

const submenuStyles = ({ isLast, offsetLeft, width, isOpen }) => ({
    transition: `transform ${duration}ms ease-in-out`,
    position: 'absolute',
    top: '100%',       
    listStyle: 'none',
    padding: '12px 0',
    margin: 0,
    width: width,      
    borderRadius: '0 0 8px 8px',
    background: colors.white,
    left: isLast ? 'auto' : offsetLeft,
    right: isLast ? 0 : 'auto',
    zIndex: '0',
    transform: 'translateY(-100%)',
    borderTop: `2px solid ${colors.keytrudaGreen}`,
    overflow: 'hidden',
    WebkitMaskImage: '-webkit-radial-gradient(colors.white, blue)',
});

const submenuTransitionStyles = {
    entering: { transform: 'translateY(-100%)' },
    entered: { transform: 'translateY(0)' },
    exiting: { transform: 'translateY(0)', zIndex: '0' },
    exited: { transform: 'translateY(-100%)', zIndex: '0', display: 'none' },
};

const SubMenuItem = styled.li`
    text-align: left;
    display: block;
`;

const SubMenuLink = styled(MenuLink)`
    display: block;
    padding: 12px 20px;
`;

const SubMenu = ({ items, isLast, isOpen, offsetLeft, width, clickHandler, destop, navType }) => {
    const subMenuRef = useRef(null);
    useTrapFocus(subMenuRef);
      
    return (
        <Transition in={isOpen} timeout={duration} >
            {state => (
                <ul
                    ref={subMenuRef}
                    class="indicationDropClass"
                    style={{
                        ...submenuStyles({ isLast, offsetLeft, width }),
                        ...submenuTransitionStyles[state],
                    }}
                >
                    {items.map((item, i) => (
                        <SubMenuItem key={i}>
                            <SubMenuLink 
                            level="2" 
                            label={item.label} 
                            url={item.url} 
                            onClick={()=>{clickHandler();}}
                            item = {item}
                            aria-expanded={item.isOpen}
                            navType= {navType}
                            activePath = {item.activePath}
                            ></SubMenuLink>
                        </SubMenuItem>
                    ))}
                </ul>
            )}
        </Transition>
    );
};

export default SubMenu;