import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, WYSIWYG, NotationBlock  } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import { indications } from '../../util/data/indications';

const Container = styled.article`
    display: flex;
    flex-flow: row wrap;
    border: solid 1px ${colors.keytrudaGreen};
    border-radius: 8px;
    overflow: hidden;
    background-color: ${colors.white};

    > * {
        padding: 30px 20px;
        min-width: 280px;
    }
`;

const IndicationContainer = styled.div`
    flex: 7 1 280px;
`;

const IndicationTitle = styled(Heading.H6)`
    margin-bottom: 10px;
`;

const IndicationContent = styled(WYSIWYG)``;

const CutpointContainer = styled.div`
    background-color: ${colors.cloud};
    flex: 1 1 280px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
`;

const CutpointDisplay = styled(Heading.H2)``;

// the naming convention shall be updated after the convo with the UX
const CutpointSpecification = styled(Heading.H4)`
    text-align: center;
`;

// @todo implement theme
const Cutpoint = props => {
    if (!props.indicationId) {
        return false;
    }
    const indicationId = props.indicationId,
    indicationName = indications[indicationId].name,
    indicationModifier = indications[indicationId].modifier,
    indicationContent = indications[indicationId].statement;

    return (
        <ComponentWrapper>
            <Container>
               <IndicationContainer>
                    {indicationName &&  (indicationModifier ?
                        <IndicationTitle as='H4' theme={props.theme} dangerouslySetInnerHTML={{__html: indicationModifier + " " + indicationName}}/>
                        :
                        <IndicationTitle as='H4' theme={props.theme} dangerouslySetInnerHTML={{__html: indicationName}}/>
                    )}
                    <IndicationContent theme={props.theme} dangerouslySetInnerHTML={{__html: indicationContent}} />
                </IndicationContainer>
                <CutpointContainer>
                    {props.subtitleHidden ? 
                        null : <Heading.Subtitle>Cut Point</Heading.Subtitle>
                    }
                    <CutpointDisplay theme="dark">{props.cutpoint}</CutpointDisplay>
                    {props.specification && <CutpointSpecification>{props.specification}</CutpointSpecification>}
                </CutpointContainer>
            </Container>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
        
        </ComponentWrapper>
    );
};

export default Cutpoint;
