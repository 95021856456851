import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import logo from '../../assets/keytruda-logo.png';
import { Heading, Text } from '../../util/typography';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { rgba } from 'polished';
import { createLocalScaleFunction } from '../../util/functions';
import { useSelector, useDispatch } from 'react-redux';
import { closeBlueberry, closeBlueberryDark } from '../../util/icons';
import {
    openGeneralModal,
    closeGeneralModal,
    allowScrolling,
    preventScrolling,
} from '../../store/actions';
import { GENERAL_MODAL_OVERLAY } from '../../constants/z-index-map';
import { getHeadingStylesByType } from '../../util/typography/Heading';
import useKeydownHandler from '../../util/accessibility/useKeydownHandler';
import useTrapFocus from '../../util/accessibility/useTrapFocus';

const ModalOverlay = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: ${rgba(colors.ink, 0.8)};
    z-index: ${GENERAL_MODAL_OVERLAY};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const ModalWrapper = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
`;

const ModalContainer = styled.div`
    max-width: 800px;
    margin: 20px;
    border-radius: 8px;
    background: ${colors.white};
    max-height: calc(100% - 40px);
    overflow: auto;
    overscroll-behavior: contain;
`;

const ModalContent = styled.div`
    position: relative;
    flex: 5 1 280px;
    min-width: 240px;
    box-sizing: border-box;
    padding: 40px ${createLocalScaleFunction(20, 280, 40, 835)} 24px;

    @media (max-width: 320px) {
        padding: 40px 20px 24px;
    }

    @media (min-width: 835px) {
        padding: 40px 40px 24px;
    }
`;

const CloseButton = styled.button`
    flex: 0 auto;
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    background: none;
    border: none;
    border-radius: 4px;
    color: ${colors.blueberry};
    padding: 4px 16px 4px 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: ${colors.blueberryDark};

        &::after {
            background-color: ${colors.mist};
            background-image: url(${closeBlueberryDark});
        }
    }

    & > span {
        display: inline-block;
        color: currentColor;
        line-height: 1;
        padding: 0.2em 0;
    }

    &::after {
        content: '';
        display: inline-block;
        width: 32px;
        height: 32px;
        background-color: transparent;
        border-radius: 50%;
        background-image: url(${closeBlueberry});
        background-size: 13.5px 13.5px;
        background-repeat: no-repeat;
        background-position: center center;
        border: none;
        color: transparent;
        padding: 0;
        margin: -15px -26px -16px 6px;
        transition: 0.3s background-color ease-in-out;
    }
`;

const ModalCloseButton = styled(CloseButton)`
    position: absolute;
    top: 0;
    right: 0;
    margin: ${createLocalScaleFunction(16, 280, 24, 835)};

    @media (min-width: 835px) {
        margin: 24px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin: 20px -10px 0;
    justify-content: center;
    align-items: stretch;

    > button,
    > a {
        margin: 0 10px 16px;
        justify-content: center;

        @media (max-width: 500px) {
            flex: 1 1 250px;
        }
    }
`;

const PrimaryButton = styled(CTA.PrimaryButton)`
    text-align: center;
    display: flex;
    align-items: center;
`;

const SecondaryButton = styled(CTA.SecondaryButton)`
    text-align: center;
    display: flex;
    align-items: center;
`;

const ContentText = styled(Heading.H5)`
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: 500px) {
        ${getHeadingStylesByType('6')}
    }
`;

// Activate GeneralModal by running dispatch(openGeneralModal(payload)), with payload being the modal content:
// const exampleModalData = {
//     copy: 'Insert copy here',
//     buttons: [ // Can contain 0, 1, or 2 buttons
//         {
//             label: 'This primary button takes you to google.com in a new tab',
//             href: 'https://google.com',
//             external: true,
//         },
//         {
//             label: 'Because this secondary button has no href, it will call clickHandler() close the modal',
//         },
//     ],
//     hideX: false // Or just leave this property out to use the X
// }

const GeneralModal = ({ general_modal_ref }) => {
    const generalModalOpen = useSelector(state => state.generalModalOpen);
    const generalModalData = useSelector(state => state.generalModalData);
    const dispatch = useDispatch();
    const modalContentRef = useRef(null);
    const lastFocusedElement = useRef(null);

    const openModal = () => {
        lastFocusedElement.current = document.activeElement;
        if (general_modal_ref.current) {
            general_modal_ref.current.focus();
        }
    };

    const clickHandler = () => {
        dispatch(closeGeneralModal());
        dispatch(allowScrolling());
        
        if (lastFocusedElement.current) {
            lastFocusedElement.current.focus();
        }
    };

    useLayoutEffect(() => {
        if (generalModalOpen) {
            openModal(); 
        }
    }, [generalModalOpen]);

    const buttonProps = generalModalData.buttons?.map(buttonData => ({
        label: buttonData.label,
        href: buttonData.href || null,
        target: buttonData.external ? 'blank' : null,
        rel: buttonData.external ? 'noopener noreferrer' : null,
        onClick: (!buttonData.href || buttonData.external) ? clickHandler : null,
    }));

    useKeydownHandler(clickHandler, 'Escape');
    useTrapFocus(general_modal_ref, generalModalOpen);

    return (
        <>
            {generalModalOpen && (
                <ModalOverlay ref={general_modal_ref} tabIndex="0">
                    <ModalWrapper>
                        <ModalContainer>
                            <ModalContent>
                                {!generalModalData.hideX && <ModalCloseButton aria-label="Close" onClick={clickHandler} />}
                                <ContentText>{generalModalData.copy}</ContentText>
                                {buttonProps && <ButtonContainer>
                                    <PrimaryButton {...buttonProps[0]} />
                                    {buttonProps[1] && <SecondaryButton {...buttonProps[1]} />}
                                </ButtonContainer>}
                            </ModalContent>
                        </ModalContainer>
                    </ModalWrapper>
                </ModalOverlay>
            )}
        </>
    );
};

export default GeneralModal;
