import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, Text, NotationBlock, WYSIWYG } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import EndpointCard from './Card';
import {componentTheme} from '../../util/functions';
import { createMarkup } from '../../util/functions';
import { createLocalScaleFunction } from '../../util/functions';

const ChartTitle = styled(Heading.H4)`
`;

const Limitation = styled(Text.Body)`
    display: block;
    margin: 10px 0;
`;

const CompareBlock = styled(Heading.H3)`
    width: 92px;
    height: 92px;
    border: 1px solid ${colors.pebble};
    background-color: ${colors.white};
    color: ${colors.ink};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
`;

const CompareWithPWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    margin-top: ${createLocalScaleFunction(16, 320, 125, 992)};
    margin-bottom: ${createLocalScaleFunction(16, 320, 0, 992)};
`

const ComparePVersusWrapper = styled(CompareBlock)`
    border-radius: 50% 50% 0 0;
    border-bottom: none;
`;

const VsText = styled(Heading.H3)`
    margin-bottom: 10px;
`

const ComparePValueWrapper = styled.div`
    background-color: ${colors.cloud};
    border: 1px solid ${colors.pebble};
    border-radius: 0 0 8px 8px;
    padding: 6px;
    width: 92px;
    margin: 0 20px;
`

const PText = styled(Heading.H7)`
    text-align: center;
`

const EndpointCardGroup = styled.div`
    flex-basis: var(--y);
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
    display: flex;
    flex-flow: column nowrap;
    justify-content: stretch;

    &:first-child:nth-last-child(5),
    &:first-child:nth-last-child(5) ~ & {
        flex-basis: 100%
    }
`;

const EndpointStyled = styled.div`
    margin-top: 20px;
    --groupBreakpoint: 600px;
    --x: (var(--groupBreakpoint) - 100%);
    --y: calc(var(--x) * 999);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    ${CompareBlock} {
        margin: 0 20px;
    }

    ${ComparePVersusWrapper} {
        margin-bottom: -24px;
    }
`;

const AdditionalInformationTitle = styled(Heading.H5)`
    margin-top: 30px;
`;

const AdditionalInformationDescription = styled(WYSIWYG)`
    display: inline-block;
    margin-top: 10px;
`;

const EndpointDisplay = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);

    const firstItem = props.cards.shift();
    
    return (
        <ComponentWrapper className={props.className}>
          {props.title && <ChartTitle as='H3' theme={theme} dangerouslySetInnerHTML={createMarkup(props.title)}/>}
            {props.limitation !== '' && (
                <Limitation forwardedAs="div" theme={theme}  dangerouslySetInnerHTML={createMarkup(props.limitation)}/>
            )}
            <EndpointStyled compareView={props.cards.length > 1}>
                <EndpointCardGroup >
                    <EndpointCard {...firstItem} />
                </EndpointCardGroup>

               {/* props.cards should only have items beyond the first, after the shift() */}
                {props.cards.length > 0 && (
                    props.cards.map((card, i) => (
                        <>
                            {props.middleP ?
                                <CompareWithPWrapper>
                                    <ComparePVersusWrapper>
                                        <VsText>vs</VsText>
                                    </ComparePVersusWrapper>

                                    <ComparePValueWrapper>
                                        <PText><i>P</i><span dangerouslySetInnerHTML={createMarkup(props.middleP)}/></PText>
                                    </ComparePValueWrapper>
                                    
                                </CompareWithPWrapper>
                                : 
                                <>
                                    <CompareBlock>vs</CompareBlock>
                                </>
                            }
                            <EndpointCardGroup>
                                <EndpointCard {...card} key={i} />
                            </EndpointCardGroup>
                        </>
                    ))
                )}
            </EndpointStyled>

            {props.additionalInformation && 
                <>
                    {props.additionalInformation.map((item, index) => 
                        <div key={index}>
                            {item.title && <AdditionalInformationTitle as='H4' dangerouslySetInnerHTML={{__html: item.title}}></AdditionalInformationTitle> }
                            {item.text && <AdditionalInformationDescription dangerouslySetInnerHTML={{__html: item.text}}></AdditionalInformationDescription> }
                        </div>
                    )}
                </>
            }
            
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme}/>}
        </ComponentWrapper>
    );
};

export default EndpointDisplay;
