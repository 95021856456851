import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Text } from '../../util/typography';
import { LinkText } from './CrossLinkSection';
import { defaultRailCardItemData } from './data';
import Link from 'gatsby-link';
import { createMarkup } from '../../util/functions';


const Box = styled(Link).attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'] || 'content_link_vd'
}))`
    position: relative;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: block;
    transition: 0.3s ease-in-out;
    border: 1px solid ${colors.pebble};
    margin: 8px;
    left:-8px;
    text-decoration: none;
    &:hover {
        border-color: ${colors.white};
        box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.05);
        
        ${Text.Body} {
            color: ${colors.blueberryDark}
        }
    }
`;

const SubHeading = styled(Text.Eyebrow)`
    display: block;
    margin-bottom: 8px;
`;

const StyledLink = styled(Link).attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'] || 'content_link_vd'
}))`
    display: block;
    text-decoration: none;
    margin-bottom: 14px;
`;

const Card = ({ text, url, eyebrow, target, links }) => {
    if (links.length) {
        return (
            <Box>
                <SubHeading>{eyebrow}</SubHeading>
                {
                    links.map((link, index) => (
                        <StyledLink 
                            key={index}
                            to={link.url || '#'}
                            target={link.target?link.target:"_self"}>
                            <LinkText dangerouslySetInnerHTML={createMarkup(link.text)} />
                        </StyledLink>
                    ))
                }
            </Box>
        );
    }
    return (
        <Box to={url} target={target?target:"_self"}>
            <SubHeading>{eyebrow}</SubHeading>
            <LinkText dangerouslySetInnerHTML={createMarkup(text)} />
        </Box>
    );
};

Card.propTypes = {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    eyebrow: PropTypes.string.isRequired,
};

Card.defaultProps = {
    ...defaultRailCardItemData,
};

export default Card;
