import React, { useContext, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Text } from '../../util/typography';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import ChatbotLaunchButton from '../Chatbot/ChatbotLaunchButton';
import { UTILITY_NAV_MENU } from '../../constants/z-index-map';
import { NAVIGATION_BREAKPOINT } from '../../constants/layout';
import { useDispatch, useSelector } from 'react-redux';
import {
    allowScrolling,
    closeAllMenuItems,
    closeOverlay,
    closeUtilityMenuItem,
    openGeneralModal,
    openOverlay,
    openUtilityMenuItem,
    preventScrolling,
} from '../../store/actions';
import SubMenu from '../MainNavigation/MainMenuLarge/SubMenuLarge';
import { caratDownWhite } from '../../util/icons';
import { useShallowEqualSelector } from '../../util/hooks';
import { DesktopNavInnerFilterOverlay, DesktopUtilityOverlay, MobileUtilityOverlay, NAV_OVERLAY_KEY, UTILITY_OVERLAY_KEY } from '../../layouts/NavContainer';
import useOnClickOutside from 'use-onclickoutside';

const bp = NAVIGATION_BREAKPOINT;

const UtilityMenu = styled.nav`
    background-color: ${colors.charcoal};
    width: 100%;
    pointer-events: auto;
    z-index: ${UTILITY_NAV_MENU};
    position: relative;

    @media (max-width: ${bp}) {

        .indicationDropClass {
            border-top: none !important;

        }
    }
`;

const UtilityMenuContainer = styled.div`
    max-width: 1032px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #444444;

    @media (max-width: ${bp}) {
        position: relative;
        z-index: 1;
    }
`;

const UtilityMenuList = styled.ul`
    flex: 1 auto;
    list-style: none;
    margin: 0 -5px 10px;
    padding: 0;
    display: ${({ hideOnSmallScreen }) => (hideOnSmallScreen ? 'none' : 'flex')};
    justify-content: space-between;
    align-items: center;
    background-color: #444444;

    @media (min-width: ${bp}) {
        display: flex;
        margin: 0;
        background-color: #444444;
    }

    &:last-child {
        flex: 1 100%;
        justify-content: flex-end;
        z-index: 10;
        background-color: #444444;
    }
`;

const UtilityMenuItem = styled.li`
    flex: 0 auto;
    margin: 0 5px;
    background-color: #444444;
        
    .leftTopNav:hover span {
    text-decoration-line: underline;
    text-decoration-color: white !important;
    text-decoration-thickness: 0.75px !important;
    text-underline-offset: 2px;
    }

    &:last-child .leftTopNav > span {
        padding-right: 6px;
        text-decoration: ${({ isOpen }) => isOpen && 'underline'};
        text-underline-offset: 2px;
        text-decoration-thickness: 0.75px !important;
    }

    @media (min-width: ${bp}) {
        background-color: #444444;
        margin: 0;
        height: 100%;

        .indicationDropClass {
            border-top: none !important;
        }

        > span, button, > a{
            padding: 0 10px;
        }
        .leftTopNav{
            > span {
                padding-right: 12px;
            }
        }
        
    }

    @media (max-width: ${bp}) {

        .indicationDropClass{
            width: 100% !important;
            left: 0px !important;           
            z-index: 0;
        }
    }
    
`;

const StyledMenuLink = styled.a.attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'] || 'top_nav_vd',
}))`
    display: block;
    white-space: nowrap;
    cursor: pointer;
    z-index: 1;
    background-color: #444444;
    height: 100%;

      ${({ hasChildren }) => `
        position: relative;  

        @media (min-width: ${bp}) {
            padding-right: 12px;
            & ul{
                z-index: 10;
            }
            &::after {
                display: block;
                position: absolute;
                content: "";
                width: 10px;
                height: 15px;
                top: 53%;
                right: 0;
                transform: translateY(-50%); 
                ${hasChildren ? `background-image: url(${caratDownWhite});` : ''}
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                transition: background-position-y .3s ease-in-out;
            }
        }    

        @media (max-width: ${bp}) {
            // position: unset;     
            z-index: 1;
            &::after {
                content: "";
                display: block;                
                width: 10px;
                height: 10px;
                ${hasChildren ? `background-image: url(${caratDownWhite});` : ''}
                background-size: contain;
                background-repeat: no-repeat;
                z-index: 1000;
                margin: -6px auto 0px auto;
            }
        }    
    `}   
`;

const MenuLabel = styled(Text.Button)`
    color: ${colors.white};
    font-size: 12px;
    padding: 0.9em 0 0.8em;
    display: inline-block;
    background: none;
    position: relative;
    z-index: -1;
    background-color: #444444;
`;

const UtilityTertiaryButton = styled(CTA.TertiaryButton)`

    > span {
        font-size: 12px;
    }

    &:hover {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-color: white;
      text-decoration-thickness: 0.75px !important;
    }
`;

export const medicalInformationModal = {
    copy: 'You will now be leaving this site and will be redirected to the Merck Medical Portal. The Merck Medical Portal is for health care professionals in the US and its territories only.',
    buttons: [
        {
            label: 'Continue',
            href: 'https://www.merckmedicalportal.com/s/medical-information?AN=P8K6FQyyRYeLD&HCP=ITEyPIfQXRnA2DA&RC=RplraQ7prRPckd',
            external: true,
        },
    ],
};

const UtilityNavigation = ({ chatDisabled,
    utility_nav_ref,
    utility_filter_ref,
    launch_button_container_ref
}) => {

    const dispatch = useDispatch();
    const parentItem = useRef(null);
    const overlayActiveSelector = useSelector(state => state.overlayActive)
    const [topItemLeftOffset, setTopItemLeftOffset] = useState(0);
    const [topItemWidth, setTopItemWidth] = useState(0);
    const close_outside_ref = useRef(null);
    const buttonRef = useRef(null);
    const lastFocusedElement = useRef(null);

    const utitlityNavSelector = state => state.utilityNavigation;
    // const overlayActiveSelector = state => state.overlayActive;
    const [isMobile, setIsMobile] = useState(false);

    const checkIsMobile = () => {
        setIsMobile(typeof window !== 'undefined' && window.innerWidth <= 874);
    };

    useEffect(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);


    //Indication Dropdown Mobile check ends

    const { openItem, mobileMenuOpen } = useShallowEqualSelector(utitlityNavSelector);

    const topItemClickHandler = (i, openItem) => {
        setTopItemLeftOffset(parentItem.current.offsetLeft);
        setTopItemWidth(parentItem.current.offsetWidth);
        itemClickHandler(i, openItem);
    };

    const itemClickHandler = (index, openItem) => {

        if (openItem === index) {
            // Clicked an open menu, close it
            dispatch(closeUtilityMenuItem(null));
            dispatch(closeOverlay());
            dispatch(allowScrolling());
            console.log('close');
        } else {
            // Otherwise open the new menu (which will close the currently open one)
            dispatch(openUtilityMenuItem(index));
            dispatch(openOverlay(UTILITY_OVERLAY_KEY));
            dispatch(preventScrolling());
            console.log('open');
        }
    }

    const closeAllHandler = () => {
        dispatch(closeAllMenuItems());
        dispatch(closeUtilityMenuItem(null));
        dispatch(closeOverlay());
        dispatch(allowScrolling());
    };

    useEffect(()=>{
         document.addEventListener('click', handleOutsideOverlay);
         return ()=> document.removeEventListener('click', handleOutsideOverlay);
    },[overlayActiveSelector])

    const handleOutsideOverlay = (e) => {
        if(overlayActiveSelector === UTILITY_OVERLAY_KEY){
            if (openItem != null) {
                closeAllHandler();
              }
        }
       }

    const handleUtilityLink = (index, hasChildren, item) => {
        if (item && (item.href || item.url)) {
            closeAllHandler();
            if (item.target) {
                window.open(item.url || item.href, "_blank");
                return;
            }
            window.location.href = item.url || item.href;

        } else if (hasChildren.length > 0) {
            topItemClickHandler(index, openItem);
        }
    };

    // For an internal link that opens in a new tab, use href instead of url and add target: '_blank'
    // Using url, CTA.setPropsFromURL will automatically set links starting with '/' to be an internal Gatsby Link
    const persistentItems = [
        {
            label: 'Prescribing Information',
            href: '/prescribing-information/',
            target: '_blank',
        },
        {
            label: 'Medication Guide',
            url: 'https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_mg.pdf',
            ariaLabel: 'Medication Guide',
            target: '_blank',

        },
        {
            label: 'Indications',
            // activePath: '/indication/',
            children: [
                {
                    label: 'View Selected Indications',
                    url: '/approved-indications',
                    activePath: '/approved-indications/'
                },
                {
                    label: 'View Earlier-Stage Indications',
                    url: '/indication/earlier-stage-cancers/',
                    activePath: '/indication/'

                }
            ]
        },
    ];

    const openModal = () => {
        lastFocusedElement.current = document.activeElement; 
        dispatch(openGeneralModal(medicalInformationModal));
        dispatch(preventScrolling());

        if (buttonRef.current) {
            buttonRef.current.focus(); 
        }
    };

    const closeModal = () => {
        dispatch(openGeneralModal({}));
        if (lastFocusedElement.current) {
            lastFocusedElement.current.focus(); 
        }
    };

    return (
        <UtilityMenu ref={utility_nav_ref} aria-label='Utility navigation'>
            <UtilityMenuContainer ref={close_outside_ref}>
                <UtilityMenuList>
                    {persistentItems.map((item, i) => (
                        <UtilityMenuItem key={i} ref={parentItem} isOpen={openItem === i} >
                            <StyledMenuLink
                                data-design-category="top_nav_vd"
                                as={(!item.url && !item.href) ? 'span' : undefined}
                                onClick={() => handleUtilityLink(i, item.children, item)}
                                //href={item.url || item.href}
                                //target={item.target || undefined}
                                {...CTA.setPropsFromURL(item.url)}
                                // onClick={item.children && item.children.length > 0 ? () => topItemClickHandler(i, openItem) : ''}
                                aria-label={item.ariaLabel}
                                hasChildren={item.children && item.children.length > 0}
                                className="leftTopNav"
                            >
                                <MenuLabel isOpen={openItem === i}>{item.label}</MenuLabel>
                            </StyledMenuLink>
                            {!isMobile && item.children && (
                                <SubMenu
                                    isOpen={openItem === i}
                                    items={item.children}
                                    leftOffset={topItemLeftOffset}
                                    width='276'
                                    navType='topNav'
                                    clickHandler={() => topItemClickHandler(i, openItem)}
                                    destop={!isMobile}
                                    
                                />
                            )}
                        </UtilityMenuItem>
                    ))}
                </UtilityMenuList>
                <UtilityMenuList hideOnSmallScreen={true}>
                    <UtilityMenuItem key={1}>
                        <UtilityTertiaryButton
                            dataDesignCategory="top_nav_vd"
                            external="true"
                            label="Ask Merck"
                            theme="light"
                            url="https://www.askmerckoncology.com"
                            target="_blank"
                        />
                    </UtilityMenuItem>
                    <UtilityMenuItem key={2}>
                        <UtilityTertiaryButton
                            tagType="button"
                            dataDesignCategory="top_nav_vd"
                            label="Medical Information"
                            theme="light"
                            external="true"
                            onClick={openModal} 
                            ref={buttonRef} 
                        />
                    </UtilityMenuItem>
                    <UtilityMenuItem key={3}>
                        <UtilityTertiaryButton
                            dataDesignCategory="top_nav_vd"
                            label="Visit Patient Site"
                            theme="light"
                            url="https://www.keytruda.com/"
                            aria-label="Visit Patient Site"
                        />
                    </UtilityMenuItem>
                    <UtilityMenuItem key={4}>
                        <ChatbotLaunchButton disabled={chatDisabled} />
                    </UtilityMenuItem>
                </UtilityMenuList>
            </UtilityMenuContainer>
            <DesktopUtilityOverlay />

            {isMobile && persistentItems.map((item, i) => (
                item.children && (
                    <SubMenu
                        key={i} // Always provide a unique key for mapped elements
                        isOpen={openItem === i}
                        items={item.children}
                        leftOffset={topItemLeftOffset}
                        width="100%"
                        navType="topNav"
                        clickHandler={() => topItemClickHandler(i, openItem)}
                    />
                )
            ))}
            <MobileUtilityOverlay />

        </UtilityMenu>
    );
};

export default UtilityNavigation;
