import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import logo from '../../assets/keytruda-logo-light.svg';
import * as CTA from '../../util/CTA';
import Link from 'gatsby-link';
import FooterMenu from './FooterMenu';

const items = [
    {
        label: 'Efficacy Information',
        children: [
            {
                label: 'Efficacy',
                url: '/efficacy/',
            },
        ],
    },
    {
        label: 'Safety',
        children: [
            {
                label: 'Selected Adverse Reactions',
                url: '/safety/adverse-reactions/',
            },
            {
                label: 'Treatment Monitoring & Management',
                url: '/safety/monitoring-managing-adverse-reactions/',
            },
        ],
    },
    {
        label: 'Biomarker Testing',
        children: [
            {
                label: 'Biomarker Overview',
                url: '/biomarker-testing/biomarker-overview/',
            },
            {
                label: 'PD-L1 Testing & Scoring',
                url: '/biomarker-testing/pd-l1/',
            },
            {
                label: 'MSI/MMR Testing',
                url: '/biomarker-testing/msi-mmr/',
            },
        ],
    },
    {
        label: 'Dosing & Preparation',
        children: [
            {
                label: 'Dosing',
                url: '/dosing/options/',
            },
            {
                label: 'Preparation, Storage & Administration',
                url: '/dosing/preparation-storage-administration/',
            },
            {
                label: 'Dose Modifications',
                url: '/dosing/dose-modifications/',
            },
        ],
    },
    {
        label: "Resources",
        children: [
            {
                label: 'Resource Center',
                url: '/resources/hcp-and-patient-resources/',
            },
            {
                label: 'Patient Support Program',
                url: '/resources/key-you-patient-support-program/',
            },
            {
                label: 'Mechanism of Action',
                url: '/resources/mechanism-of-action/',
            },
            /*{
                label: 'Surgeon Resource Center',
                url: '/resources/surgeon-resources/',
            }*/
        ]
    }
];

export const FooterContainer = styled.div`
    max-width: 100%;
    background: ${colors.charcoal};
    padding-top: 20px;
`;

const FooterNavigationContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: content-box;
    max-width: 992px;
    margin: 0 auto;
    padding: 20px 20px 0;

    > div {
        &:first-child {
            min-width: 233px;
            display: flex;
            flex: 1 1 135px;
            margin: 0;
        }
        &:last-child {
            flex: 2 1 473px;
            display: flex;
            justify-content: space-between;
        }
    }
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    min-height: 120px;

    img {
        width: 160px;
        height: 50.966px;
        padding-bottom: 0.725em;
    }
`;

export const FooterNavigation = (props) => {
    return (
        <>
                <FooterNavigationContainer>
                    <div>
                        <LogoContainer>
                            <div>
                                <Link title="Home" to="/" data-design-category='bottom_nav_vd'>
                                    <img src={logo} alt="KEYTRUDA® (pembrolizumab): Website for Health Care Professionals" />
                                </Link>
                                <div>
                                    <CTA.TertiaryButton
                                        dataDesignCategory="bottom_nav_vd"
                                        theme="light"
                                        label="Visit Patient Site"
                                        url="https://www.keytruda.com"
                                        aria-label="Visit Patient Site"
                                    />{' '}
                                </div>
                                <div>
                                <CTA.TertiaryButton
                                        dataDesignCategory="bottom_nav_vd"
                                        theme="light"
                                        label="Contact Us"
                                        url="/contact-us/"
                                    />
                                </div>
                            </div>
                        </LogoContainer>
                    </div>

                    <FooterMenu items={items} />
                </FooterNavigationContainer>
        </>
    );
};

export {default as FooterLegal } from './FooterLegal';
