import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import MenuLink from './MenuLinkLarge';
import SubMenu from './SubMenuLarge';
import { MAIN_NAV_MAIN_MENU_LIST } from '../../../constants/z-index-map';
import { NAVIGATION_BREAKPOINT } from '../../../constants/layout';

const MainMenuList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: none;
    position: relative;
    @media (min-width: ${NAVIGATION_BREAKPOINT}) {
        flex: 9 2 auto;
        display: flex;
        justify-content: flex-end;
        z-index: ${MAIN_NAV_MAIN_MENU_LIST};
    }
`;

const StyledMainMenuItem = styled.li`
    flex: 0 1 160px;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: stretch;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
`;

const MainMenuItem = ({ className, clickHandler, item, isLast, isOpen }) => {
    const parentItem = useRef(null);
    const [topItemLeftOffset, setTopItemLeftOffset] = useState(0);
    const [topItemWidth, setTopItemWidth] = useState(0);

    const topItemClickHandler = () => {
        setTopItemLeftOffset(parentItem.current.offsetLeft);
        setTopItemWidth(parentItem.current.offsetWidth);
        clickHandler();
    };

    return (
        <StyledMainMenuItem
            ref={parentItem}
            onClick={clickHandler ? topItemClickHandler : ''}
        >
            <MenuLink
                level="1"
                label={item.label}
                url={item.url}
                hasChildren={item.children && item.children.length > 0}
                isOpen={isOpen}
                activePath={item.activePath}
                aria-expanded={isOpen}
            />
            {item.children && (
                <SubMenu
                    isOpen={isOpen}
                    isLast={isLast}
                    items={item.children}
                    leftOffset={topItemLeftOffset}
                    width={topItemWidth * 2}
                />
            )}
        </StyledMainMenuItem>
    );
};

const MainMenuLarge = ({ items, openItem, clickHandler, closeAllHandler, styled_nav_ref }) => (
    <MainMenuList ref={styled_nav_ref} >
        {items.map((item, i) => (
            <MainMenuItem
                key={i}
                clickHandler={!item.url ? () => clickHandler(i, openItem) : closeAllHandler}
                item={item}
                isLast={i === items.length - 1}
                isOpen={openItem === i}
            />
        ))}
    </MainMenuList>
);

export default MainMenuLarge;
