import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Text } from '../../util/typography';
import { between } from 'polished';

/* Response */
const ResponsePercent = styled.span`
    font-size: clamp(35px, 7.8vw, 44px); 
    /* I would like to discuss if this is a good approach or whether this should live somewhere within fonts */
    sup {
        font-size: max(42%, 10px);
        vertical-align: top;
        position: relative;
        top: 0.2em;
    }
`;

const ResponseText = styled(Text.Body)`
    ${'' /* new text styles */}
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
`;

const StyledResponse = styled.div`
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 10px;


    ${ResponsePercent} + ${ResponseText} {
        margin-top: 6px;
    }
`;

const StatLegend = styled(Text.Footnote)`
    display: block;
    margin-top: 10px;
`;

const Response = ({ percent, text, ci }) => {
    return (
        <StyledResponse>
            <ResponsePercent dangerouslySetInnerHTML={{__html: percent}}/>
            <ResponseText dangerouslySetInnerHTML={{__html: text}}></ResponseText>
            {ci && <StatLegend dangerouslySetInnerHTML={{__html: `(${ci})`}} /> }
        </StyledResponse>
    );
};

/* Response Tile */

const CompareResponseWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
`;

const ResponseDivider = styled.span`
    width: 1px;
    background: ${({bgColor}) => (bgColor === 'gradient') ? colors.keytrudaGreen : colors.pebble};
    flex: 0 0 1px;
`;

const StyledResponseTile = styled.div`
    --chip: ${colors.pebble};
    --chipText: ${colors.charcoal};
    --chipBorder: ${colors.pebble};
    background: ${colors.white};
    border-top: 1px solid ${colors.pebble};
    // border-bottom-left-radius: 8px;
    // border-bottom-right-radius: 8px;
    text-align: center;
    padding: ${between('20px', '40px', '320px', '1032px')} 0
        ${between('30px', '40px', '320px', '1032px')};
    flex-basis: var(--basis);
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: stretch;
    display: flex;
`;

const ResponseTile = (props) => {
    return (
        <StyledResponseTile>
            <CompareResponseWrapper>
                <Response percent={props.completeResponseRate} text='Complete<br>Response' ci={props.completeResponseCI} />
                <ResponseDivider bgColor={props.bgColor}/>
                <Response percent={props.partialResponseRate} text='Partial<br>Response' ci={props.partialResponseCI} />
            </CompareResponseWrapper>
        </StyledResponseTile>
    );
};

export default ResponseTile;
