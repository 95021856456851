import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import RateTile from './RateTile';
import ResponseTile from './ResponseTile';

const StyledCard = styled.div`
    --componentBreakpoint: 900px;
    --multiplier: (var(--componentBreakpoint) - 100%);
    --basis: calc(var(--multiplier) * 999);
    --bg: ${({ bgColor }) =>
        bgColor === 'gradient'
            ? `radial-gradient(circle at 23% 0, #006ead, #35864b 67%, #36864a 87%)`
            : `linear-gradient(139deg, #444444 11%, #696969 88%)`};

    border: 1px solid ${colors.pebble};
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    > * {
        flex: 1 1 280px;
    }
`;

const Card = ( props ) => {
    const response =  props.completeResponseRate || props.completeResponseCI || props.partialResponseRate || props.partialResponseCI;
    //if the label says 'Keytruda', make the box theme green
    const bgColor = props.label.toUpperCase().includes('KEYTRUDA') ? 'gradient' : 'grey';
    return (
        <StyledCard bgColor={bgColor}>
            <RateTile {...props} />
            {response && <ResponseTile {...props} bgColor={bgColor}/>}
        </StyledCard>
    );
};

export default Card;
