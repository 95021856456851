import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { SortMethods } from './SortMethods';
import { Text } from '../../util/typography';
import { searchBlueberry, closeCharcoal } from '../../util/icons';
import { CSSTransition } from 'react-transition-group';

import * as SVGIcon from './icons';

const breakpoints = {
    showSortIcons: '800px',
    showSearchPrompt: '500px',
};

const FilterHeader = styled.div`
    display: flex;
    margin-bottom: 40px;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    position: relative;
`;

const SortWrapper = styled.div`
    flex: 1 1 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-right: 20px;
    overflow: hidden;
    transition: max-width 0.3s ease-in-out, opacity 0.3s ease-in-out;
    box-sizing: border-box;
    margin-left: -1px; //fix border clipping on some browsers
    &.hide-enter {
        max-width: 50%;
        opacity: 0;
    }

    &.hide-enter-active {
        max-width: 50%;
        transition: max-width 0.3s ease-in-out, opacity 0.3s ease-in-out;
        margin-right: 20px;
    }

    &.hide-enter-done {
        opacity: 1;
        margin-right: 20px;
    }

    &.hide-exit {
        opacity: 0;
    }

    &.hide-exit-active {
        max-width: 0;
        transition: max-width 0.3s ease-in-out, opacity 0.3s ease-in-out;
        margin-right: 0;
    }

    &.hide-exit-done {
        max-width: 0;
        opacity: 0;
        margin-right: 0;
    }

    > span {
        flex: 0 100%;
        white-space: nowrap;
        padding-top: 0.2em;
        margin-bottom: 10px;
    }
`;

const SortBG = styled.div`
    border: 1px solid ${colors.blueberry};
    border-radius: 22px;
    min-width: 215px;
    flex: 1 5 215px;
    display: flex;
    overflow: hidden;
    position: relative;
    background-color: ${colors.white};
    height: 44px;
    box-sizing: border-box;

    .hidden & {
        flex: 0 auto;
    }
`;

function moveIndicator(sortMethod) {
    let buttonIndex;

    switch (sortMethod) {
        case SortMethods.DEFAULT:
            buttonIndex = 0;
            break;
        // case SortMethods.DATE:
        //     buttonIndex = 1;
        //     break;
        case SortMethods.A_Z:
            buttonIndex = 1; //change when/if date sort is added back
            break;
        default:
            break;
    }
    let offset = ((buttonIndex / 2) * 100).toFixed(2) + 1 + '%'; //change when/if date sort is added back
    return offset;
}

const FilterIndicator = styled.div`
    width: 50%; //change when/if date sort is added back
    position: absolute;
    height: 101%;
    z-index: 0;
    background: ${colors.blueberry};
    border-radius: 22px;
    border: 1px solid ${colors.blueberry};
    left: ${({ sortMethod }) => moveIndicator(sortMethod)};
    transition: left 0.3s ease-in-out;
`;

const SearchWrapper = styled.div`
    flex: 1 1 50%;
    display: inline-flex;
    align-items: center;
    position: relative;
    min-width: 44px;
    transition: max-width 0.3s ease-in-out;
    justify-self: flex-end;
    max-width: 44px;

    &.active {
        max-width: 100%;
    }

    @media (min-width: ${breakpoints.showSearchPrompt}) {
        max-width: 50%;
    }

    &.expand-enter-active,
    &.expand-enter-done {
        max-width: 100%;
    }
`;

const StyledSearch = styled.input`
    border-radius: 1em 1em;
    border: 1px solid ${colors.slate};
    border-radius: 22px 22px;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    width: 0;
    height: 44px;
    padding: 10px 20px;
    background-image: url(${searchBlueberry});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;
    font-family: 'proxima-nova', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${colors.charcoal};
    transition: all 0.3s ease-in-out, width 0;
    
    vertical-align: text-bottom;

    &::placeholder {
        color: transparent;
    }

    &:focus, &:hover {
        border-color: ${colors.blueberry};
        box-shadow: 0 0 23px 0 rgba(0, 0, 0, 0.1);
    }

    .expand-enter & {
        padding-left: 0;
        padding-right: 0;

        @media (min-width: ${breakpoints.showSearchPrompt}) {
            padding-left: 60px;
            padding-right: 20px;
        }

        background-position: left 22px center;
    }

    .expand-enter-active & {
        width: 100%;
    }

    .expand-enter-done & {
        width: 100%;
        padding-left: 60px;
        padding-right: 60px;
        background-position: left 22px center;

        &::placeholder {
            color: inherit;
        }
    }

    .expand-exit & {
        width: 100%;
        background-position: left 12px center;
        transition: background-position 0.2s ease-in-out, width 0.3s ease-in-out;
    }

    .expand-exit-active {
        width: 0;
        transition: background-position 0.3s ease-in-out, width 0.3s ease-in-out;
        background-position: left 0 center;
    }

    .expand-exit-done {
        padding-left: 0;
        padding-right: 0;
        background-position: center;
    }

    @media (min-width: ${breakpoints.showSearchPrompt}) {
        width: 100%;
        padding: 0 20px 0 60px;
        background-position: left 22px center;

        &::placeholder {
            color: inherit;
        }
    }
`;

const IconWrapper = styled.div`
    display: none;
    margin-right: 10px;
    //offset for AlphaSortIcon, which has different alignment
    ${({ icon }) =>
        icon === 'alphaSort' &&
        `
        margin-bottom: -.3em;
    `}

    > svg {
        display: block;
        width: 100%;
        height: auto;
        fill: ${colors.blueberry};
        transition: fill 0.3s ease-in-out;

        .active & {
            fill: ${colors.white};
        }
    }

    @media (min-width: ${breakpoints.showSortIcons}) {
        display: block;
    }
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 50%; //change when/if date sort is added back
    border: 0;
    background-color: transparent;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
    margin: 0;
    z-index: 1;
    padding: 0.85em 0;
    box-sizing: border-box;

    > span {
        color: ${colors.blueberry};
        display: inline-block;
        line-height: 1em;
    }

    &.active > span {
        color: ${colors.white};
        transition: color 0.3s ease-in-out;
    }
`;

const StyledClose = styled.button`
    display: none;
    position: absolute;
    background-color: transparent;
    border: 0;
    outline: 0;
    right: 10px;
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin: 0;
    padding: 15px 22px;
    background-image: url(${closeCharcoal});
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &:hover {
        color: blue;
    }

    .expand-enter-active & {
        display: block;
        opacity: 0;
    }

    .expand-enter-done & {
        display: block;
        opacity: 1;
    }
`;

const FilterNav = ({ sortMethod, search, onChangeFilter }) => {
    const tabListRef = useRef(null);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const handleKeyDown = (e) => {
        if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
            e.preventDefault();
            const tabs = tabListRef.current.querySelectorAll('button');
            const tabsLength = tabs.length;
      
            if (e.key === 'ArrowRight') {
              setFocusedIndex((prevIndex) => (prevIndex + 1) % tabsLength);
            } else if (e.key === 'ArrowLeft') {
              setFocusedIndex((prevIndex) => (prevIndex - 1 + tabsLength) % tabsLength);
            }
        }
    }

    useEffect(() => {
        const tabs = tabListRef.current.querySelectorAll('button');
        if (focusedIndex >= 0 && focusedIndex < tabs.length) {
          tabs[focusedIndex].focus();
        }
    }, [focusedIndex]);

      
    const handleOnBlur = () => {
        if(!search.trim()) {
            onChangeFilter(SortMethods.DEFAULT);
        }
    }

    return (
        <>
            <FilterHeader>
                <CSSTransition
                    in={sortMethod === SortMethods.SEARCH}
                    timeout={300}
                    classNames="expand"
                >
                    <SearchWrapper>
                        <StyledSearch
                            type="text"
                            placeholder="Search cancer types"
                            onFocus={() => onChangeFilter(SortMethods.SEARCH, '')}
                            onBlur={handleOnBlur}
                            value={search}
                            onChange={e => onChangeFilter(SortMethods.SEARCH, e.target.value)}
                            aria-label="Search cancer types"
                        />
                        <StyledClose onClick={() => onChangeFilter(SortMethods.DEFAULT)} 
                        aria-label="Clear Search"/>
                    </SearchWrapper>
                </CSSTransition>
                <CSSTransition
                    in={sortMethod !== SortMethods.SEARCH}
                    timeout={300}
                    classNames="hide"
                >
                    <SortWrapper>
                        <Text.Eyebrow>SORT BY</Text.Eyebrow>
                        <SortBG role="tablist" aria-label="Sort by" onKeyDown={handleKeyDown} ref={tabListRef}>
                            <FilterIndicator sortMethod={sortMethod} />
                            <StyledButton
                                icon="defaultSort"
                                id="filternav-tab-1"
                                type="button"
                                role="tab"
                                aria-controls="filternav-tabpanel-1"
                                className={sortMethod === SortMethods.DEFAULT && 'active'}
                                aria-selected={sortMethod === SortMethods.DEFAULT}
                                tabIndex={sortMethod === SortMethods.DEFAULT ? 0 : -1}
                                onClick={() => {
                                    moveIndicator(0);
                                    onChangeFilter(SortMethods.DEFAULT);
                                }}
                            >
                                <IconWrapper>
                                    <SVGIcon.DefaultSortIcon />
                                </IconWrapper>
                                <Text.Button>Default</Text.Button>
                            </StyledButton>
                            {/* Date Sort removed at launch for logistical/legal reasons. Will be revisited in future phases. */}
                            {/* <StyledButton
                                icon="dateSort"
                                className={sortMethod === SortMethods.DATE && 'active'}
                                onClick={() => {
                                    moveIndicator(1);
                                    onChangeFilter(SortMethods.DATE);
                                }}
                            >
                                <IconWrapper>
                                    <SVGIcon.DateSortIcon />
                                </IconWrapper>
                                <Text.Button>Date</Text.Button>
                            </StyledButton> */}
                            <StyledButton
                                icon="alphaSort"
                                type="button"
                                role="tab"
                                id="filternav-tab-2"
                                aria-controls="filternav-tabpanel-2"
                                className={sortMethod === SortMethods.A_Z && 'active'}
                                aria-selected={sortMethod === SortMethods.A_Z}
                                tabIndex={sortMethod === SortMethods.A_Z ? 0 : -1}
                                onClick={() => {
                                    moveIndicator(1); //change to 2 when/if date sort is added back
                                    onChangeFilter(SortMethods.A_Z);
                                }}
                            >
                                <IconWrapper icon="alphaSort">
                                    <SVGIcon.AlphaSortIcon />
                                </IconWrapper>
                                <Text.Button>A to Z</Text.Button>
                            </StyledButton>
                        </SortBG>
                    </SortWrapper>
                </CSSTransition>
            </FilterHeader>
        </>
    );
};

export default FilterNav;
