import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, Text, WYSIWYG, NotationBlock } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import { componentTheme } from '../../util/functions';
import { createMarkup } from '../../util/functions';

const StyledBreakoutContentRow = styled.div`
    /* magic numbers */
    /* --sectionContainerMaxWidth: 992px; */
    --gridSpacing: 20px;
    /* --childFlexBasis: calc((var(--sectionContainerMaxWidth) / 2) - (2 * var(--gridSpacing))); */
    --iconSize: 60px;
    --iconPadding: 16px;
    --iconContainer: calc(var(--iconSize) + ((var(--iconPadding) + 1px) * 2));
    --halfIconContainer: calc(var(--iconContainer) / 2);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 calc((var(--gridSpacing) / 2) * -1) calc(var(--gridSpacing) * -1);

    & > * {
        flex-basis: calc(50%- (2 * var(--gridSpacing)));
        flex-shrink: 1;
        flex-grow: 1;
        /* sibling horizontal spacing handled in the row */
        margin-left: calc(var(--gridSpacing) / 2);
        margin-right: calc(var(--gridSpacing) / 2);
        min-width: 280px;
    }
`;

const BreakoutContentRow = props => {
    const theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        <ComponentWrapper>
            <StyledBreakoutContentRow>{props.children}</StyledBreakoutContentRow>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme} />}
        </ComponentWrapper>
    )
};

const StyledCard = styled.div`
    position: relative;
    width: 100%;
    padding: 68px 20px 30px;
    padding: calc(var(--halfIconContainer) + var(--gridSpacing)) var(--gridSpacing) 30px;
    border: 1px solid ${colors.pebble};
    border-radius: 8px;
    background-color: white;
    /* vertical margin handled in card */
    margin-top: var(--halfIconContainer);
    margin-bottom: var(--gridSpacing);
    flex: 1 1 calc(33.33% - (var(--gridSpacing) * 2));
    ${({ minWidth }) => minWidth || '280px'};
    flex-basis: ${({ columns }) => columns ? 'calc(33.33% - (var(--gridSpacing) * 2))' : 'calc(50% - (var(--gridSpacing) * 2))'};
    transition: 0.3s ease; 
    cursor: ${({isClickable})=> isClickable ?  'pointer': 'default'};
       
    // &:hover {
    //     border-color: ${({isClickable})=> isClickable ?  colors.white : ''};
    //     box-shadow: ${({isClickable})=> isClickable ?  '0 4px 20px 0 rgba(0, 0, 0, 0.16)': ''};
    // }

    // &:hover > div:first-child {
    //     border-color:${({isClickable})=> isClickable ?  colors.white : ''};
    //     box-shadow: ${({isClickable})=> isClickable ?  '0 4px 20px 0 rgba(0, 0, 0, 0.16)': ''};
    //  }
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: var(--iconContainer);
    height: var(--iconContainer);
    border: 1px solid ${colors.pebble};
    border-radius: 50%;
    background-color: white;
    overflow: hidden;
    padding: var(--iconPadding);
    transition: 0.3s ease;        

    & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    // &:hover {
    //     border-color:${({isClickable})=> isClickable ?  colors.white : ''};
    //     box-shadow: ${({isClickable})=> isClickable ?  '0 4px 20px 0 rgba(0, 0, 0, 0.16)': ''};
    //     }
`;

const CardContent = styled(WYSIWYG)`
    max-width: 824px;
    margin: auto;
    height: 100%;
`;

const BreakoutCardHeader = styled(Heading.H6)`
    text-align: ${({ shouldCenter }) => (shouldCenter ? 'center' : 'left')};
`;

const BreakoutCardText = styled(Text.Body)`
    display: ${({ card }) => (card === 'earlyStage' ? 'flex' : 'block')};
    flex-direction: ${({ card }) => (card === 'earlyStage' && 'column')};
    justify-content: ${({ card }) => (card === 'earlyStage' && 'space-between')};
    height: 100%;

    * + & {
        margin-top: 16px;
    }
`;

export const BreakoutContentCard = ({ icon, title, children, columns, card, isClickable, url, setIsCardHovered }) => {
    const hasBodyText = !!children;

    const handleClick = (url) => {
    window.location.href = url;
    }

    const onCardMouseEnter = () => {
        setIsCardHovered(true);
    };

    const onCardMouseLeave = () => {
        setIsCardHovered(false);
    };

    return (
        <StyledCard columns={columns} isClickable={isClickable} onClick={() => isClickable ? handleClick(url) : undefined} onMouseEnter={onCardMouseEnter} onMouseLeave={onCardMouseLeave}
>
            <IconWrapper isClickable={isClickable}>
                {typeof icon === 'function' && icon()}
            </IconWrapper>
            {title && (
                <BreakoutCardHeader as='H3' $shouldCenter={!hasBodyText} dangerouslySetInnerHTML={createMarkup(title)} />
            )}
            <CardContent>
                {hasBodyText && <BreakoutCardText card={card} >{children}</BreakoutCardText>}
            </CardContent>
        </StyledCard>
    );
};

export default BreakoutContentCard;
export { BreakoutContentRow };
